import React, { memo, useCallback, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { useIncentivesWizardStateManager } from 'site-modules/shared/components/incentives/incentives-wizard/state-manager/use-incentives-wizard-state-manager';
import { Incentive } from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/incentive';
import { Context } from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/context';
import { WizardHead } from 'site-modules/shared/components/incentives/incentives-wizard/wizard-head';
import { WizardProgressBar } from 'site-modules/shared/components/incentives/incentives-wizard/wizard-progress-bar';
import { LoadingDots } from 'site-modules/shared/components/loading-dots/loading-dots';
import {
  RESULTS_SCREEN_ID,
  TRANSACTION_TYPE_QUESTION_ID,
} from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';
import { ANIMATION_TIMEOUT } from 'site-modules/shared/components/incentives/incentives-wizard/constants/animations';
import { useFocusOnStepChange } from 'site-modules/shared/components/incentives/incentives-wizard/hooks/use-focus-on-step-change';
import { IncentivesWizardTransition } from 'site-modules/shared/components/incentives/incentives-wizard/incentives-wizard-transition';

import './incentives-wizard.scss';
import './incentives-wizard-animation.scss';

/**
 * Smart Incentives wizard component
 * @description See overall description here https://edmundswiki.atlassian.net/wiki/spaces/CP/pages/2997059589/EV+Incentives+Wizard
 * and technical documentation here https://edmundswiki.atlassian.net/wiki/spaces/CP/pages/3222503425/EV+Incentives+Wizard+Technical+documentation
 */
export const IncentivesWizard = memo(props => {
  const {
    incentives,
    onFilterIncentives,
    flowProcessor,
    flowBuilder,
    questions,
    incentivesWizardStepFactory,
    stepFactoryComponentsMap,
    context,
    initialStepIndex,
    onExit,
    onFinish,
    creativeId,
    onStartConvexAnimation,
    onScrollToTop,
    initialEligibleIncentivesIds,
    savedAnswers,
    onStartOver: onStartOverProp,
  } = props;
  const wizardRef = useRef();
  const animatedContainerHeight = useRef();

  const setStyle = useCallback((styleProperty, value) => {
    animatedContainerHeight.current.style[styleProperty] = value;
  }, []);

  const { state, onAnswer, onBack, onStartOver } = useIncentivesWizardStateManager({
    flowProcessor,
    flowBuilder,
    incentives,
    initialStepIndex,
    initialEligibleIncentivesIds,
    onFilterIncentives,
    context,
    savedAnswers,
  });
  const {
    flowSteps,
    currentStepIndex,
    stepMeta,
    answers,
    eligibleIncentiveIds,
    incentives: allIncentives,
    isLoading,
    activeCategory,
    flowIncentives,
  } = state;
  const currentFlowStep = flowSteps.at(currentStepIndex);
  const currentStepConfig = questions[currentFlowStep];

  useFocusOnStepChange(wizardRef, onScrollToTop, currentStepIndex);

  useLayoutEffect(() => {
    onStartConvexAnimation(false);
  }, [currentStepIndex, onStartConvexAnimation]);

  const handleFinish = useCallback(
    totalAmount => {
      onFinish(totalAmount, state);
    },
    [onFinish, state]
  );

  const handleStartOver = useCallback(() => {
    onStartOver();
    onStartOverProp();
  }, [onStartOver, onStartOverProp]);

  if (!currentStepConfig) {
    return null;
  }

  const isResultScreen = currentStepConfig.id === RESULTS_SCREEN_ID;

  return (
    <div
      className={classnames('incentives-wizard rounded-16 w-100 bg-white mx-auto pos-r', { result: isResultScreen })}
      role="region"
      aria-label="Incentives Wizard"
      ref={animatedContainerHeight}
    >
      <CSSTransition
        in={!currentStepConfig.hideHeadSection}
        timeout={ANIMATION_TIMEOUT}
        classNames="show-head"
        mountOnEnter
        unmountOnExit
      >
        <div
          className={classnames('pos-a w-100', {
            'show-head': currentFlowStep === TRANSACTION_TYPE_QUESTION_ID,
          })}
        >
          <WizardHead eligibleIncentiveIds={eligibleIncentiveIds} allIncentives={allIncentives} />
          <WizardProgressBar currentStepIndex={currentStepIndex} flowSteps={flowSteps} />
        </div>
      </CSSTransition>
      <div className={classnames('no-focus pos-r', { 'p-1_5': !isResultScreen })} ref={wizardRef} tabIndex={-1}>
        <IncentivesWizardTransition currentStepIndex={currentStepIndex} wizardRef={wizardRef} onSetStyle={setStyle}>
          {incentivesWizardStepFactory.createStep({
            stepConfig: currentStepConfig,
            stepFactoryComponentsMap,
            stepMeta,
            answers,
            incentives: flowIncentives,
            allIncentives,
            eligibleIncentiveIds,
            onAnswer,
            onBack,
            context,
            onExit,
            onFinish: handleFinish,
            flowSteps,
            creativeId,
            onStartConvexAnimation,
            activeCategory,
            flowSkipped: initialStepIndex === -1 && initialStepIndex === currentStepIndex,
            onStartOver: handleStartOver,
          })}
        </IncentivesWizardTransition>

        <CSSTransition
          in={isLoading}
          timeout={ANIMATION_TIMEOUT}
          classNames="loading"
          appear
          mountOnEnter
          unmountOnExit
        >
          <div className="loading-screen pos-a left-0 right-0 bottom-0 bg-white rounded-16 d-flex align-items-center justify-content-center">
            <LoadingDots dotClassName="wizard-dots bg-cool-gray-60 mx-0_25" />
          </div>
        </CSSTransition>
      </div>
    </div>
  );
});

IncentivesWizard.displayName = 'IncentivesWizard';

IncentivesWizard.propTypes = {
  incentives: PropTypes.arrayOf(Incentive),
  onFilterIncentives: PropTypes.func.isRequired,
  flowProcessor: PropTypes.shape({
    processIncentivesUpdate: PropTypes.func.isRequired,
    processAnswer: PropTypes.func.isRequired,
  }).isRequired,
  flowBuilder: PropTypes.shape({
    buildFlow: PropTypes.func.isRequired,
  }).isRequired,
  questions: PropTypes.shape({}).isRequired,
  incentivesWizardStepFactory: PropTypes.shape({
    createStep: PropTypes.func.isRequired,
  }).isRequired,
  stepFactoryComponentsMap: PropTypes.shape({}).isRequired,
  context: Context,
  initialStepIndex: PropTypes.number,
  onExit: PropTypes.func,
  onFinish: PropTypes.func,
  creativeId: PropTypes.string,
  onStartConvexAnimation: PropTypes.func.isRequired,
  onScrollToTop: PropTypes.func.isRequired,
  initialEligibleIncentivesIds: PropTypes.arrayOf(PropTypes.string),
  savedAnswers: PropTypes.shape({}),
  onStartOver: PropTypes.func,
};

IncentivesWizard.defaultProps = {
  incentives: undefined,
  context: {},
  initialStepIndex: 0,
  onExit: noop,
  onFinish: noop,
  creativeId: undefined,
  initialEligibleIncentivesIds: [],
  savedAnswers: undefined,
  onStartOver: noop,
};
