import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { getTotalRebateAmount } from 'site-modules/shared/utils/incentives/get-rebate-amount-from-incentives';
import { Incentive } from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/incentive';
import { WizardPrice } from 'site-modules/shared/components/incentives/incentives-wizard/wizard-price';

import './wizard-head.scss';

export const WizardHead = memo(({ eligibleIncentiveIds, allIncentives }) => {
  const total = useMemo(() => {
    const incentives = allIncentives.filter(({ id }) => eligibleIncentiveIds.includes(id));
    return getTotalRebateAmount(incentives);
  }, [allIncentives, eligibleIncentiveIds]);

  return (
    <div className="wizard-head bg-blue-100 px-1_5 py-1 d-flex justify-content-between align-items-center">
      <b>You’re saving</b>
      <WizardPrice value={total} />
    </div>
  );
});

WizardHead.displayName = 'WizardHead';

WizardHead.propTypes = {
  eligibleIncentiveIds: PropTypes.arrayOf(PropTypes.string),
  allIncentives: PropTypes.arrayOf(Incentive),
};

WizardHead.defaultProps = {
  eligibleIncentiveIds: [],
  allIncentives: [],
};
