import React from 'react';
import { DEFAULT_QUESTION } from 'site-modules/shared/components/incentives/incentives-wizard/constants/question-types';

/* eslint-disable react/prop-types */
function createQuestion({
  stepConfig,
  stepMeta,
  answers,
  eligibleIncentiveIds,
  incentives,
  allIncentives,
  onAnswer,
  onBack,
  context,
  flowSteps,
  creativeId,
  activeCategory,
  componentsMap,
}) {
  const StepComponent = componentsMap[stepConfig.type] || componentsMap[DEFAULT_QUESTION];

  return (
    <StepComponent
      stepConfig={stepConfig}
      stepMeta={stepMeta}
      answers={answers}
      eligibleIncentiveIds={eligibleIncentiveIds}
      incentives={incentives}
      allIncentives={allIncentives}
      onAnswer={onAnswer}
      onBack={onBack}
      context={context}
      flowSteps={flowSteps}
      creativeId={creativeId}
      key={stepConfig.id}
      activeCategory={activeCategory}
    />
  );
}

export const incentivesWizardQuestionFactory = {
  createQuestion,
};
