import { curryRight, flow as flowFn } from 'lodash';
import {
  setEligibilityQuestionsQualifierMap,
  setFlowSteps,
} from 'site-modules/shared/components/incentives/incentives-wizard/state-manager/actions';
import { isOEMRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-oem-rebate';
import { PROGRAM_TYPES } from 'site-modules/shared/components/incentives/incentives-wizard/constants/program-types';
import { CATEGORIES } from 'site-modules/shared/components/incentives/incentives-wizard/constants/categories';
import { isFederalRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-federal-rebate';
import { isLocalEvRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-local-ev-rebate';
import { isTaxCreditRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-tax-credit-rebate';
import { STATIC_PROGRAM_QUESTIONS } from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';

import { flowStepCreator } from 'site-modules/shared/components/incentives/incentives-wizard/flow-builders/flow-step-creator';
import { eligibilityFactorsMapper } from 'site-modules/shared/components/incentives/incentives-wizard/flow-builders/eligibility-questions-mapper';
import { actualizerFlowBasedOnUserAnswer } from 'site-modules/shared/components/incentives/incentives-wizard/flow-builders/actualize-flow-based-on-answer';
import { filterOutRedundantQuestions } from 'site-modules/shared/components/incentives/incentives-wizard/flow-builders/filter-out-redunant-questions';

/**
 * Distributes input incentives across supported program types.
 *
 * @param {Array} incentives
 * @returns {Programs | {}}
 * @example
 *  Input:
 *    [inc1, inc2]
 *
 *  Output:
 *   {
 *     [PROGRAM_TYPES.EV_REBATE]: { flow: [...], incentives: [...], category: 'FEDERAL' },
 *     [PROGRAM_TYPES.CHARGER_INSTALLATION]: { flow: [...], incentives: [...], category: 'LOCAL' },
 *     ...
 *   }
 */
function getAvailableProgramsFromIncentives(incentives) {
  const programs = incentives.reduce(
    (result, incentive) => {
      const { programType } = incentive;
      if (!(programType in result)) {
        if (isOEMRebate(incentive)) {
          return {
            ...result,
            [PROGRAM_TYPES.OEM]: {
              flow: [...STATIC_PROGRAM_QUESTIONS[PROGRAM_TYPES.OEM]],
              incentives: [...result[PROGRAM_TYPES.OEM].incentives, incentive],
              category: CATEGORIES.LOCAL,
            },
          };
        }
        return result;
      }

      if (isFederalRebate(incentive)) {
        return {
          ...result,
          [programType]: {
            flow: [...STATIC_PROGRAM_QUESTIONS[programType]],
            incentives: [...result[programType].incentives, incentive],
            category: CATEGORIES.FEDERAL,
          },
        };
      }

      if (isLocalEvRebate(incentive) || isTaxCreditRebate(incentive)) {
        return {
          ...result,
          [PROGRAM_TYPES.TAX_CREDIT]: {
            flow: [...STATIC_PROGRAM_QUESTIONS[PROGRAM_TYPES.TAX_CREDIT]],
            incentives: [...result[PROGRAM_TYPES.TAX_CREDIT].incentives, incentive],
            category: CATEGORIES.LOCAL,
          },
        };
      }

      return {
        ...result,
        [programType]: {
          flow: [...STATIC_PROGRAM_QUESTIONS[programType]],
          incentives: [...result[programType].incentives, incentive],
          category: CATEGORIES.LOCAL,
        },
      };
    },
    {
      [PROGRAM_TYPES.EV_REBATE]: { flow: [], incentives: [], category: CATEGORIES.FEDERAL },
      [PROGRAM_TYPES.CHARGER_INSTALLATION]: { flow: [], incentives: [], category: CATEGORIES.LOCAL },
      [PROGRAM_TYPES.TAX_CREDIT]: { flow: [], incentives: [], category: CATEGORIES.LOCAL },
      [PROGRAM_TYPES.OEM]: { flow: [], incentives: [], category: CATEGORIES.LOCAL },
    }
  );

  return Object.fromEntries(Object.entries(programs).filter(([, { flow }]) => flow.length));
}

/**
 * Creates questions flow for initializing the wizard.
 *
 * @param {Object} state - wizard state
 * @param {function} dispatch
 * @param {Array} incentives
 * @param {Object} context
 */
function buildFlow(state, dispatch, incentives, context) {
  const { flowSteps, currentStepIndex, activeCategory, answers } = state;

  const { programs, eligibilityQuestionsQualifiersMap } = flowFn([
    getAvailableProgramsFromIncentives,
    eligibilityFactorsMapper.map,
  ])(incentives);

  const newFlowSteps = flowFn([
    curryRight(actualizerFlowBasedOnUserAnswer.actualize)(answers),
    filterOutRedundantQuestions(context),
    curryRight(flowStepCreator.createFlow)({ flowSteps, currentStepIndex, activeCategory }),
  ])(programs);

  dispatch(setFlowSteps(newFlowSteps));
  dispatch(setEligibilityQuestionsQualifierMap(eligibilityQuestionsQualifiersMap));
}

export const flowBuilder = {
  buildFlow,
};
