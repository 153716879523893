import {
  ADJUSTED_GROSS_INCOME_LOCAL_QUESTION_ID,
  CUSTOMER_OF_QUESTION_ID,
  INSTALL_EV_CHARGER_QUESTION_ID,
  LOWER_INCOME_YEAR_QUESTION_ID,
  TAX_FILING_STATUS_LOCAL_QUESTION_ID,
} from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';
import { PROGRAM_TYPES } from 'site-modules/shared/components/incentives/incentives-wizard/constants/program-types';
import { getCurrentYear } from 'site-modules/shared/utils/time-util';

function actualizeListOfChargerInstallationQuestions(programQuestions, answers) {
  if (!Object.hasOwn(answers, INSTALL_EV_CHARGER_QUESTION_ID)) {
    return programQuestions;
  }
  const answerOnInstallEvChargerQuestion = answers[INSTALL_EV_CHARGER_QUESTION_ID][0];
  const { flow, ...restOptions } = programQuestions;
  return !answerOnInstallEvChargerQuestion
    ? { flow: flow.filter(question => question !== CUSTOMER_OF_QUESTION_ID), ...restOptions }
    : programQuestions;
}

const actualizeChargerInstallationProgram = (programs, answers) => {
  if (!programs[PROGRAM_TYPES.CHARGER_INSTALLATION]) return {};
  return {
    [PROGRAM_TYPES.CHARGER_INSTALLATION]: actualizeListOfChargerInstallationQuestions(
      programs[PROGRAM_TYPES.CHARGER_INSTALLATION],
      answers
    ),
  };
};

const actualizeListOfTaxCreditProgram = (programs, answers) => {
  const { flow, ...restOptions } = programs[PROGRAM_TYPES.TAX_CREDIT];
  const QUESTIONS_ID_TO_EXCLUDE = [ADJUSTED_GROSS_INCOME_LOCAL_QUESTION_ID, TAX_FILING_STATUS_LOCAL_QUESTION_ID];
  if (
    programs[PROGRAM_TYPES.EV_REBATE] &&
    answers?.[LOWER_INCOME_YEAR_QUESTION_ID]?.[0] &&
    answers[LOWER_INCOME_YEAR_QUESTION_ID][0] !== getCurrentYear()
  ) {
    return {
      flow: flow.filter(question => !QUESTIONS_ID_TO_EXCLUDE.includes(question)),
      ...restOptions,
    };
  }
  return { flow, ...restOptions };
};

const actualizeTaxCreditProgram = (programs, answers) => {
  if (!programs[PROGRAM_TYPES.TAX_CREDIT]) return {};
  return {
    [PROGRAM_TYPES.TAX_CREDIT]: actualizeListOfTaxCreditProgram(programs, answers),
  };
};

/**
 * Remove redundant question IDs from a flow for a specific program based on the user answers.
 *
 * @param {Programs} programs
 * @param {Object.<string, Array.<any>>}answers
 * @returns {Programs}
 */
export function actualize(programs, answers) {
  return {
    ...programs,
    ...actualizeChargerInstallationProgram(programs, answers),
    ...actualizeTaxCreditProgram(programs, answers),
  };
}

export const actualizerFlowBasedOnUserAnswer = {
  actualize,
};
