import {
  QUESTION,
  SCREEN,
} from 'site-modules/shared/components/incentives/incentives-wizard/constants/question-variants';
import { incentivesWizardScreenFactory } from 'site-modules/shared/components/incentives/incentives-wizard/factories/incentives-wizard-screen-factory';
import { incentivesWizardQuestionFactory } from 'site-modules/shared/components/incentives/incentives-wizard/factories/incentives-wizard-question-factory';

function createStep({
  stepConfig,
  stepFactoryComponentsMap,
  stepMeta,
  answers,
  eligibleIncentiveIds,
  incentives,
  allIncentives,
  onAnswer,
  context,
  onExit,
  onBack,
  flowSteps,
  creativeId,
  onFinish,
  onStartConvexAnimation,
  activeCategory,
  flowSkipped,
  onStartOver,
}) {
  if (stepConfig.variant === QUESTION) {
    return incentivesWizardQuestionFactory.createQuestion({
      stepConfig,
      stepMeta,
      answers,
      eligibleIncentiveIds,
      incentives,
      allIncentives,
      onAnswer,
      onBack,
      context,
      flowSteps,
      creativeId,
      activeCategory,
      componentsMap: stepFactoryComponentsMap[QUESTION],
    });
  }

  if (stepConfig.variant === SCREEN) {
    return incentivesWizardScreenFactory.createScreen({
      stepConfig,
      stepMeta,
      answers,
      eligibleIncentiveIds,
      incentives,
      allIncentives,
      onAnswer,
      context,
      onExit,
      flowSteps,
      creativeId,
      onStartConvexAnimation,
      onFinish,
      flowSkipped,
      onStartOver,
      componentsMap: stepFactoryComponentsMap[SCREEN],
    });
  }

  return null;
}

export const incentivesWizardStepFactory = {
  createStep,
};
