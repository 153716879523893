/**
 * @typedef {Object} WizardState
 * @property {Array} incentives - all incentives that the wizard worked with throughout its life cycle
 * @property {Array.<string>} eligibleIncentiveIds - array of incentive identifiers the user is eligible to
 * @property {Array.<string>} flowSteps - array of question ids
 * @property {Object} stepMeta - an object that stores meta information necessary for screens of questions
 * @property {number} currentStepIndex - current question index in flowSteps
 * @property {string} activeCategory - current active category. Can be LOCAL or FEDERAL
 * @property {boolean} isLoading - when this flag is true, the master makes an API call to get new incentives
 * @property {Object.<string, Array.<*>>} answers - answers to wizard questions
 * @property {Object.<string, Array.<string>>} eligibilityQuestionsQualifiersMap - question - qualifiers array map.
 * Used to create correct eligibility filter. See createEligibilityFactorFilter function from client/site-modules/shared/components/incentives/incentives-wizard/utils/create-eligibility-factor-filter.js.
 * Example: {questionId: [qualifier1, qualifier2]}
 * @property {Array} flowIncentives - incentives to build or rebuild flow
 */

/**
 *
 * @param {Array} [incentives=[]]
 * @param {number} [initialStepIndex=0]
 * @param {Array} [initialEligibleIncentivesIds=[]]
 * @param {Object} [savedAnswers={}]
 * @returns {WizardState}
 */
export const getInitialWizardState = ({
  incentives = [],
  initialStepIndex = 0,
  initialEligibleIncentivesIds = [],
  savedAnswers = {},
}) => ({
  incentives,
  eligibleIncentiveIds: initialEligibleIncentivesIds,
  flowSteps: [],
  stepMeta: {},
  currentStepIndex: initialStepIndex,
  activeCategory: '',
  isLoading: false,
  answers: savedAnswers,
  eligibilityQuestionsQualifiersMap: {},
  flowIncentives: incentives,
});
