import { curry, flow as flowFn } from 'lodash';
import {
  CATEGORIES,
  CATEGORIES_SEQUENCE,
} from 'site-modules/shared/components/incentives/incentives-wizard/constants/categories';
import {
  GOLD_CATEGORY_QUESTIONS_MAP,
  RESULT_FEDERAL_REBATES_SCREEN_ID,
  RESULT_LOCAL_REBATES_SCREEN_ID,
  RESULTS_SCREEN_ID,
  START_SCREENS,
} from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';

/**
 * Distributes questions by two categories.
 *
 * @param {Programs} programs
 * @returns {{LOCAL: Array<string>, FEDERAL: Array<string>}}
 * @example
 *  Example input
 *       [PROGRAM_TYPES.EV_REBATE]: { flow: [1,2,3], incentives: [inc1,inc2], category: CATEGORIES.FEDERAL },
 *       [PROGRAM_TYPES.CHARGER_INSTALLATION]: { flow: [4,5,6], incentives: [inc4, inc4], category: CATEGORIES.LOCAL },
 *       [PROGRAM_TYPES.TAX_CREDIT]: { flow: [1,2,4,7,8,9], incentives: [inc5, inc6], category: CATEGORIES.LOCAL },
 *  Example output
 *      {
 *        [CATEGORIES.FEDERAL]: [1,2,3],
 *        [CATEGORIES.LOCAL]: [4,5,6,1,2,4,7,8,9],
 *      }
 */
function distributeFlowByCategories(programs) {
  return Object.entries(programs).reduce(
    (result, [, { flow, category }]) => ({ ...result, [category]: [...result[category], ...flow] }),
    { [CATEGORIES.FEDERAL]: [], [CATEGORIES.LOCAL]: [] }
  );
}

/**
 * Filters out duplicate questions and questions that are not contained in the gold card.
 * Gold card - it is the list of all supported questions and their correct sequence.
 * See GOLD_CATEGORY_QUESTIONS_MAP from client/site-modules/shared/components/incentives/incentives-wizard/questions/questions.js
 *
 * @param {{LOCAL: Array<string>, FEDERAL: Array<string>}} categoryFlowMap
 * @returns {{LOCAL: Array<string>, FEDERAL: Array<string>} | {}}
 */
function filterOutRedundantQuestions(categoryFlowMap) {
  return Object.entries(categoryFlowMap).reduce(
    (result, [category, flow]) => ({
      ...result,
      [category]: Array.from(new Set(flow)).filter(flowStep =>
        GOLD_CATEGORY_QUESTIONS_MAP[category].includes(flowStep)
      ),
    }),
    {}
  );
}

const sortFlow = (flow, goldFlow) => {
  const sortedFlow = [...flow];
  return sortedFlow.sort((a, b) => goldFlow.indexOf(a) - goldFlow.indexOf(b));
};

/**
 * Sorts steps in the correct order by gold card inside each category ( FEDERAL, LOCAL ).
 *
 * @param {{LOCAL: Array<string>, FEDERAL: Array<string>}} categoryFlowMap - Array<string> - disordered array
 * @returns {{LOCAL: Array<string>, FEDERAL: Array<string>}} categoryFlowMap - Array<string> - ordered array
 */
function sortFlowInsideCategory(categoryFlowMap) {
  const sortedCategoriesFlowMap = categoryFlowMap;
  Object.entries(categoryFlowMap).forEach(([category, flow]) => {
    sortedCategoriesFlowMap[category] = sortFlow(flow, GOLD_CATEGORY_QUESTIONS_MAP[category]);
  });

  return sortedCategoriesFlowMap;
}

function getCategorySequence(activeCategory) {
  return [activeCategory, ...CATEGORIES_SEQUENCE.filter(category => category !== activeCategory)].filter(
    category => !!category
  );
}

/**
 * Sorts questions depending on the active category.
 *
 * @param {string} activeCategory - LOCAL or FEDERAL
 * @param {{LOCAL: Array<string>, FEDERAL: Array<string>}} categoryFlowMap
 * @returns {Array<string>}
 * @example
 *  Example input:
 *    activeCategory: LOCAL
 *    categoryFlowMap: { [CATEGORIES.FEDERAL]: [1,2,3], [CATEGORIES.LOCAL]: [4,5,6,7] }
 *  Example output:
 *    [4,5,6,7,1,2,3]
 */
function sortFlowByCategory(activeCategory, categoryFlowMap) {
  const desiredCategoriesSequence = getCategorySequence(activeCategory);
  return desiredCategoriesSequence.flatMap(category => categoryFlowMap[category]);
}

const categoryInstantIneligibleScreens = {
  [CATEGORIES.FEDERAL]: RESULT_FEDERAL_REBATES_SCREEN_ID,
  [CATEGORIES.LOCAL]: RESULT_LOCAL_REBATES_SCREEN_ID,
};

function insertMissingEligibilityScreen(activeCategory, flow) {
  return !flow.includes(categoryInstantIneligibleScreens[activeCategory]) && activeCategory
    ? [categoryInstantIneligibleScreens[activeCategory], ...flow]
    : flow;
}

/**
 * Creates a new flow of questions for the wizard.
 *
 * @param {Programs} programs
 * @param {Object} options - necessary options to create a new flow
 * @param {Array.<string>} options.flowSteps - current array of question ids
 * @param {number} options.currentStepIndex - current question index from flowStep array
 * @param {string} options.activeCategory - active category (LOCAL or FEDERAL)
 * @returns {Array.<string>} - an array of questions ids
 */
function createFlow(programs, { flowSteps, currentStepIndex, activeCategory }) {
  const newFlow = flowFn([
    distributeFlowByCategories,
    filterOutRedundantQuestions,
    sortFlowInsideCategory,
    curry(sortFlowByCategory)(activeCategory),
    curry(insertMissingEligibilityScreen)(activeCategory),
  ])(programs);

  const passedSteps = flowSteps.slice(0, currentStepIndex + 1);

  return Array.from(new Set([...START_SCREENS, ...passedSteps, ...newFlow, RESULTS_SCREEN_ID]));
}

export const flowStepCreator = {
  createFlow,
};
