import { get, mergeWith, omit } from 'lodash';
import { CHECKING_ELIGIBILITY_PROGRAM_QUESTIONS_MAP } from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';
import { getQualifierMapper } from 'site-modules/shared/components/incentives/incentives-wizard/flow-builders/qualifier-question-mappers/qualifier-mapper-factory';
import { ELIGIBILITY_FIELD_MAP_TARGET } from 'site-modules/shared/components/incentives/incentives-wizard/constants/eligibility';

/**
 * Creates an array of eligibility factors from incentives
 *
 * @param {Array} incentives
 * @returns {Array.<EligibilityFactor> | []}
 * @example
 *  Input:
 *    [{rebateOverridesByEligibility: [factor1, factor2]}, {rebateOverridesByEligibility: []}, {rebateOverridesByEligibility: [factor3]}]
 *
 *  Output:
 *    [[factor1, factor2, factor3]]
 */
const getAllEligibilityFactors = incentives =>
  incentives.flatMap(incentive => {
    const rebateOverridesByEligibility = get(incentive, 'rebateOverridesByEligibility', []);
    if (!rebateOverridesByEligibility.length) return [];
    return rebateOverridesByEligibility.map(rebateEligibility => get(rebateEligibility, 'eligibilityFactor'));
  });

// Used to filter out all invalid fields, which should not be mapped to questions. (if additional fields are received in EligibilityFactor)
const OMITTED_FIELDS = ['qualifier', 'qualifierDescription', 'id'];

/**
 * Groups qualifiers by eligibility fields for all eligibility factors.
 * Excluding fields with false values and fields listed in the OMITTED_FIELDS array.
 *
 * @param {Array} incentives
 * @returns { Object.<keyof EligibilityFactor  , Array.<string>> | Object} - key is one of the EligibilityFactor fields, value is an array of qualifiers
 * @example
 *
 *  Input:
 *    [ {rebateOverridesByEligibility: [{
 *          qualifier: 'Filing (Current / Previous year)',
 *          taxFilingStatus: 'Married-Separately',
 *          maxIncome: 150000,
 *          householdSize: 0,
 *          assistanceParticipant: false,
 *        }]
 *      }
 *    ]
 *
 *  Output:
 *    {
 *      maxIncome: ['Filing (Current / Previous year)'],
 *      taxFilingStatus: ['Filing (Current / Previous year)'],
 *    }
 */
const getEligibilityFields = incentives => {
  const eligibilityFields = {};
  const eligibilityFactors = getAllEligibilityFactors(incentives);
  if (!eligibilityFactors.length) return eligibilityFields;

  eligibilityFactors.forEach(eligibilityFactor => {
    Object.entries(eligibilityFactor).forEach(([key, value]) => {
      if (value) {
        eligibilityFields[key] = Array.from(new Set([...get(eligibilityFields, key, []), eligibilityFactor.qualifier]));
      }
    });
  });

  return omit(eligibilityFields, OMITTED_FIELDS);
};

/**
 * Groups qualifiers by eligibility fields for each of the available programs (EV Rebate, Tax Credit, ...)
 *
 * @param {Array.<Array.<string, ProgramDetails>>} programEntries
 * @returns {ProgramEligibilityFields}
 * @example
 *  Input
 *    [['EV Rebate', {incentives: [inc1, ..., incN], flow: [], categories: LOCAL}],]
 *
 *  Output:
 *    {'EV Rebate': { taxFilingStatus: ['Filing (Current / Previous year)'], maxIncome: ['Filing (Current / Previous year)'],},
 *      ...
 *    }
 */
function getEligibilityFieldsByProgram(programEntries) {
  return Object.fromEntries(
    programEntries.map(([programName, { incentives }]) => {
      const eligibilityFields = getEligibilityFields(incentives);
      return [programName, eligibilityFields];
    })
  );
}

/**
 * Maps eligibility fields to question IDs.
 *
 * @param {string} qualifier
 * @param {string} field
 * @param {'display' | 'filter'} usageTarget - defines purpose for which we do mapping as for some fields we do ask additional questions,
 * which should not be reflected in eligibility filter. Example client/site-modules/shared/components/incentives/incentives-wizard/flow-builders/qualifier-question-mappers/filing-current-year-mapper.js
 * @returns {Array.<string>} - an array of question ids
 * @example
 *  Input:
 *    qualifier = 'assistance'
 *    field = 'assistanceParticipant'
 *    usageTarget = 'display'
 *  Output:
 *    [PARTICIPATE_IN_QUALITY_PROGRAMS_QUESTION_ID]
 */
const mapQualifier = (qualifier, field, usageTarget) => getQualifierMapper(qualifier)(field, usageTarget);

/**
 * Creates an array of question IDs for a qualifier with multiple values such as 'qualifier1;qualifier2'
 *
 * @param {string} multiQualifier
 * @param {string} field
 * @param {'display' | 'filter'} usageTarget - defines purpose for which we do mapping as for some fields we do ask additional questions,
 * which should not be reflected in eligibility filter. Example client/site-modules/shared/components/incentives/incentives-wizard/flow-builders/qualifier-question-mappers/filing-current-year-mapper.js
 * @returns {Array.<string>} - an array of question ids
 */
const mapMultiQualifier = (multiQualifier, field, usageTarget) => {
  const qualifiers = multiQualifier.split(';');

  return qualifiers.flatMap(qualifier => mapQualifier(qualifier, field, usageTarget));
};

/**
 * Maps eligibility fields to question IDs using specific qualifier mapper
 * as for different qualifiers we can use different question ids even for the same eligibility field
 *
 * @param {string} qualifier
 * @param {string} field
 * @param {'display' | 'filter'} usageTarget - defines purpose for which we do mapping as for some fields we do ask additional questions,
 * which should not be reflected in eligibility filter. Example client/site-modules/shared/components/incentives/incentives-wizard/flow-builders/qualifier-question-mappers/filing-current-year-mapper.js
 * @returns {Array.<string>}
 */
const mapQualifierSpecificFieldToQuestions = (qualifier, field, usageTarget) => {
  if (qualifier.includes(';')) {
    return mapMultiQualifier(qualifier, field, usageTarget);
  }

  return mapQualifier(qualifier, field, usageTarget);
};

/**
 * Creates an array of questions based on eligibility fields of each eligibility factor.
 * @param {string} programName
 * @param {Object.<keyof EligibilityFactor, Array<string>>} eligibilityFields
 * @returns {Array.<string> | []} - an array of questions ids
 */
const getDynamicQuestions = (programName, eligibilityFields) =>
  Array.from(
    new Set(
      Object.entries(eligibilityFields).flatMap(([field, qualifiers]) =>
        qualifiers.reduce(
          (questions, qualifier) => [
            ...questions,
            ...mapQualifierSpecificFieldToQuestions(qualifier, field, ELIGIBILITY_FIELD_MAP_TARGET.DISPLAY),
          ],
          []
        )
      )
    )
  ).concat(CHECKING_ELIGIBILITY_PROGRAM_QUESTIONS_MAP[programName] || []);

/**
 * Adds dynamic questions for each program if needed.
 * Initially, the flow for each program is built only from static questions that do not depend on incentives. (for example EV_REBATE_PROGRAM_QUESTIONS_STATIC)
 * But some questions depend on qualifiers and eligibility fields (dynamic questions).
 * So it is necessary to calculate these questions and update the flow for the corresponding program.
 *
 * @param {Programs} programs
 * @param {Array.<Array.<string, ProgramDetails>>} programEntries
 * @param {ProgramEligibilityFields} programEligibilityFields
 * @returns {Programs}
 */
function appendQuestionsBasedOnEligibilityFields(programs, programEntries, programEligibilityFields) {
  const updatedPrograms = programs;

  programEntries.forEach(([programName, { flow }]) => {
    const dynamicQuestions = getDynamicQuestions(programName, programEligibilityFields[programName]);
    updatedPrograms[programName].flow = [...flow, ...dynamicQuestions];
  });

  return updatedPrograms;
}

/**
 * Groups qualifiers by eligibility questions.
 * This structure is needed to properly create the EligibilityFactorFilter.
 *
 * @param {ProgramEligibilityFields} programEligibilityFields
 * @returns {Object.<string, Array.<string>>} - key is questionId, value is an array od qualifiers
 * @example
 *  Input:
 *    {
 *      Charger Installation: {}
 *      Tax Credit: {
 *        "taxFilingStatus": [ "Filing (Current / Previous year)" ],
 *        "maxIncome": [ "Filing (Current / Previous year)" ],
 *        "assistanceParticipant": [ "Assistance" ]
 *       }
 *    }
 *
 *  Output:
 *    {
 *      'a94s2551-8f71-11ee-b9d1-0242ac120002': ['Filing (Current / Previous year)']
 *      'a95s2551-8f71-11ee-b9d1-0242ac120002': ['Filing (Current / Previous year)']
 *      'fc28959d-a5e0-4890-96a6-00af1aec9a3d': ['Assistance']
 *    }
 */
const getQuestionsQualifierMap = programEligibilityFields => {
  const combinedEligibilityFields = Object.values(programEligibilityFields).reduce(
    (eligibilityFields, eligibilityFieldsFromProgram) =>
      mergeWith(eligibilityFields, eligibilityFieldsFromProgram, (objValue, srcValue) => {
        if (Array.isArray(objValue)) {
          return Array.from(new Set(objValue.concat(srcValue)));
        }
        return srcValue;
      }),
    {}
  );

  return Object.entries(combinedEligibilityFields).reduce((result, [fieldName, qualifierList]) => {
    const qualifierQuestions = qualifierList.reduce(
      (questionsGroupedByQualifier, qualifier) => ({
        ...questionsGroupedByQualifier,
        [qualifier]: [
          ...(questionsGroupedByQualifier[qualifier] || []),
          ...mapQualifierSpecificFieldToQuestions(qualifier, fieldName, ELIGIBILITY_FIELD_MAP_TARGET.FILTER),
        ],
      }),
      {}
    );

    return Object.entries(qualifierQuestions).reduce(
      (questionsQualifiersMap, [qualifier, questions]) =>
        mergeWith(
          questionsQualifiersMap,
          Object.fromEntries(questions.map(question => [question, [qualifier]])),
          (objValue, srcValue) => {
            if (Array.isArray(objValue)) {
              return Array.from(new Set(objValue.concat(srcValue)));
            }
            return srcValue;
          }
        ),
      result
    );
  }, {});
};

/**
 * Updates flow with dynamic questions for each program and create map of eligibility question to qualifiers array for building correct eligibility filter.
 *
 * @param {Programs} programs
 * @returns {{ programs: Programs, eligibilityQuestionsQualifiersMap: Object.<string, Array.<string>> }}
 */
function map(programs) {
  const programEntries = Object.entries(programs);
  const programEligibilityFields = getEligibilityFieldsByProgram(programEntries);
  const updatedFlows = appendQuestionsBasedOnEligibilityFields(programs, programEntries, programEligibilityFields);
  const eligibilityQuestionsQualifiersMap = getQuestionsQualifierMap(programEligibilityFields);

  return { programs: updatedFlows, eligibilityQuestionsQualifiersMap };
}

export const eligibilityFactorsMapper = {
  map,
};
