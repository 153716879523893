import React from 'react';
import { DEFAULT_SCREEN } from 'site-modules/shared/components/incentives/incentives-wizard/constants/screen-types';

/* eslint-disable react/prop-types */
function createScreen({
  stepConfig,
  stepMeta,
  answers,
  eligibleIncentiveIds,
  incentives,
  allIncentives,
  onAnswer,
  context,
  onExit,
  onFinish,
  flowSteps,
  creativeId,
  onStartConvexAnimation,
  flowSkipped,
  onStartOver,
  componentsMap,
}) {
  const StepComponent = componentsMap[stepConfig.type] || componentsMap[DEFAULT_SCREEN];

  return (
    <StepComponent
      stepConfig={stepConfig}
      stepMeta={stepMeta}
      answers={answers}
      eligibleIncentiveIds={eligibleIncentiveIds}
      incentives={incentives}
      allIncentives={allIncentives}
      onAnswer={onAnswer}
      onFinish={onFinish}
      context={context}
      onExit={onExit}
      flowSteps={flowSteps}
      creativeId={creativeId}
      key={stepConfig.id}
      onStartConvexAnimation={onStartConvexAnimation}
      flowSkipped={flowSkipped}
      onStartOver={onStartOver}
    />
  );
}

export const incentivesWizardScreenFactory = {
  createScreen,
};
